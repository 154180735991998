.home-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;  /* Full viewport height */
    background-image: url('../images/boardwalk-bg.jpg');  /* Path to your background image */
    background-size: cover;  /* Cover the entire screen */
    background-position: center;  /* Center the image */
    background-repeat: no-repeat;  /* Do not repeat the image */
}

.home-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;  /* Text color, you can change it as needed */
    text-shadow: 1px 1px 2px black;  /* Optional: Add a text shadow for better readability */
    text-align: center;
}
  
.home-container h1 {
    font-size: 3rem;  /* Adjust font size as needed */
}

.home-info {
    position: absolute;
    top: 10%;  /* Adjust as needed */
    right: 5%;  /* Adjust as needed */
    color: white;
    text-align: right;
    margin-top: min(20px, 10%);
}

.home-logo {
    width: 150px;  /* Adjust as needed */
    margin-bottom: 10px;
}

.home-address h1 {
    font-size: 2rem;  /* Adjust as needed */
    margin: 0;
}

.home-address p {
    margin: 0;
    font-size: 1rem;  /* Adjust as needed */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .home-info {
        position: static;
        text-align: center;
        margin-top: 80px;
    }

    .home-logo {
        width: 100px;  /* Adjust as needed */
    }

    .home-address h1 {
        font-size: 1.5rem;  /* Adjust as needed */
    }

    .home-address p {
        font-size: 0.9rem;  /* Adjust as needed */
    }
}
  