.routes-page {
    display: flex;
    height: calc(100vh - 60px);
}
  
.left-pane {
    background-color: #f0f0f0;
    padding: 20px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

/* Custom scrollbar for WebKit browsers */
.left-pane::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }
  
  .left-pane::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background of the scrollbar track */
  }
  
  .left-pane::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Round the corners of the scrollbar thumb */
  }
  
  .left-pane::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }
  
  /* Hide scrollbar arrows in WebKit browsers */
  .left-pane::-webkit-scrollbar-button {
    display: none; /* Hide the arrows */
  }
  
  /* Custom scrollbar for Firefox */
  .left-pane {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f0f0f0; /* Color of the scrollbar thumb and track */
  }
  
  /* Hide scrollbar arrows in Firefox */
  .left-pane {
    scrollbar-gutter: stable both-edges;
  }

.left-pane h2 {
    margin-bottom: 10px;
}

.left-pane ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.left-pane li {
    padding: 5px 0;
    cursor: pointer;
}

.left-pane li:hover {
    background-color: #ddd;
}

.map-pane {
    flex-grow: 1;
    padding: 20px;
    overflow: hidden;
}
  