@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.nav {
    background: #3165a0;  /* Semi-transparent background */
    padding: 1rem 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;  /* Ensure it's on top of other content */
    font-family: 'Roboto Light', serif;
    height: 20px;
}
  
.nav ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
  
.nav li {
    margin: 0 1rem;
}
  
.nav a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}
  